import * as React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ReactHtmlParser from 'react-html-parser';
import ShareButtons from '../../components/ShareButtons';
import Otos from '../../images/otos-brain.png';

const Post = ({ data, location }) => {
  const posts = data.allPosts.nodes[0];
  console.log(posts);
  const url = location.pathname;
  const testUrl = typeof window !== 'undefined' ? window.location.href : '';
  console.log(url);
  console.log(testUrl);
  return (
    <Layout>
      <Seo
        title={posts.title}
        description={posts.seo_desciption}
        keywords={(posts.seo_keyword, posts.seo_keyword2, posts.seo_keyword3)}
      />

      <div
        class="max-w-5xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 mt-10 md:mt-32 mb-10 p-2"
        key={posts}
      >
        <img class="object-cover object-center w-full h-fit" src={posts.url} alt={posts.title} />

        <div class="p-6">
          <div>
            <span class="text-xs font-medium text-purple-700 uppercase border rounded-full border-purple-700 px-2 py-1">
              {posts.seo_keyword1} | {posts.seo_keyword2}
            </span>
            <h1 class="block mt-4 text-2xl font-semibold text-gray-800">{posts.title}</h1>
            <p class="text-sm text-gray-600 dark:text-gray-400">
              {' '}
              {ReactHtmlParser(data.allPosts.nodes[0].body.body)}
            </p>
          </div>

          <div class="mt-8">
            <div class="flex items-center relative">
              <div class="flex items-center">
                <img
                  class="object-cover w-10 h-10 rounded-full bg-gray-300"
                  src={Otos}
                  alt="Avatar"
                />
                <div class="mx-2 font-semibold text-gray-700 dark:text-gray-200">
                  {posts.author}
                </div>
              </div>
              <span className="text-purple-700">|</span>
              <span class="mx-1 text-xs text-gray-600 dark:text-gray-300">{posts.created_at}</span>
              <div className="italic text-purple-800 md:absolute md:right-0">
                <Link to="/posts">Voir plus d'articles</Link>
              </div>
            </div>
            <ShareButtons url={testUrl} title={posts.title} description={posts.seo_description} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query MyQuery($id: String) {
    allPosts(filter: { id: { eq: $id } }) {
      nodes {
        author
        created_at(formatString: "D MMMM YYYY", locale: "fr")
        postId
        seo_keyword1
        seo_keyword2
        seo_keyword3
        seo_keyword4
        seo_keyword5
        seo_title
        title
        url
        id
        body {
          body
        }
      }
    }
  }
`;

export default Post;
